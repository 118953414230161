.login_btn {
  background: #a81a31 !important;
  width: -webkit-fill-available;
  height: 52px;
  margin-top: 15px;
  border: none;
  color: white;
  font-size: initial;
  box-shadow: 5px 7px 20px -4px black;
  border-radius: 7px;
  cursor: pointer;
}

.css-17e4284-MuiPaper-root-MuiAppBar-root {
  z-index: 990;
}
