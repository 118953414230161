.modal_close {
  position: relative;
  top: 0;
  left: 95%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.modal_close_with_title {
  width: 98%;
  padding-left: 1%;
  padding-right: 1%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 10px;
}

.modal_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  min-width: 80px;
  min-height: 30px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #a81a31;
  color: #a81a31;
  cursor: pointer;
  /* margin-left: auto; */
  margin-right: 15px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
}

.modal_btn:hover {
  background-color: #a81a31;
  font-weight: 600;
  color: white;
}

.modal_close_svg {
  cursor: pointer;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_close_svg:hover {
  background-color: rgb(237, 232, 232);
}

.modal_close_with_title h3 {
  padding: 0;
  margin: 0;
}

@media (max-width: 500px) {
  .modal_close {
    left: 5%;
  }

  .modal_close_with_title {
    left: 5%;
  }
}
