.item_list_div {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  font-size: 15px;
  margin-top: 10px;
  padding: 2px;
}

.item_list_div strong {
  margin-left: 5px;
}

.item_list_div:hover {
  color: #a81a31;
}

.MuiButtonBase-root {
  box-shadow: none !important;
}
