.css-1hgyc67-MuiToolbar-root {
  height: fit-content;
}

.css-1tfylnl-MuiToolbar-root {
  min-height: 60px;
}

.expand {
  transition: all 0.1s ease-in-out;
  margin-right: 15px;
}

#apply-flatpickr-btn {
  background-color: #a81a31;
  color: #fff;
  font-weight: 600;
  outline: none;
  border: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
  margin-left: 20px;
  margin-right: 10px;
  border-radius: 5px;
}

.appended_controls {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: auto;
  max-height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #fff;
  align-items: center;
}

#close-flatpickr-btn {
  color: #a81a31;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
}

#navItem_ic {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transition: all 0.3s ease-in-out;
}

#navItem {
  border-radius: 0;
  font-size: 12.5px;
  font-weight: 600;
  color: black;
  height: 40px;
  margin: 0px 0px 0px;
}

#navItem_child {
  border-radius: 0;
  font-size: 12.5px;
  font-weight: 600;
  color: black;
  height: 40px;
  margin: 0px 0px 0px;
}

#navItem:hover {
  color: #a81a31;
}

.toolbar {
  display: flex;
  column-gap: 10px;
}

.toolbar button {
  background-color: #eeebeb;
  color: #000;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  min-width: 40px;
  font-weight: 600;
  box-shadow: 0 0 2px #ccc;
}

.tabs-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.custom-tab {
  min-width: 80px;
  height: 56px;
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: 15px;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
}

.custom-tab-disabled {
  min-width: 80px;
  height: 56px;
  display: flex;
  flex: 1;
  align-items: center;
  column-gap: 15px;
  justify-content: center;
  font-weight: 600;
  cursor: not-allowed;
}

.toolbar .active {
  background-color: #a81a21;
  color: #fff;
  border: none;
  outline: none;
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  min-width: 40px;
}

.custom-tooltip {
  padding: 10px;
  background-color: #a81a31;
}

#navItem_child:hover {
  color: #a81a31;
}

#navItem_child:hover #navItem_ic {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

#navItem:hover #navItem_ic {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.left {
  transform: rotate(90deg);
}

.down {
  transform: rotate(0deg);
}

.blinking {
  -webkit-animation: 0.8s blink ease infinite;
  -moz-animation: 0.8s blink ease infinite;
  -ms-animation: 0.8s blink ease infinite;
  -o-animation: 0.8s blink ease infinite;
  animation: 0.8s blink ease infinite;
}

@keyframes blink {
  from {
    opacity: 1;
    transform: scale(0.5);
  }

  to {
    opacity: 0.1;
    transform: scale(1.5);
  }
}

@-moz-keyframes blink {
  from {
    opacity: 1;
    transform: scale(0.5);
  }

  to {
    opacity: 0.1;
    transform: scale(1.5);
  }
}

@-webkit-keyframes blink {
  from {
    opacity: 1;
    transform: scale(0.5);
  }

  to {
    opacity: 0.1;
    transform: scale(1.5);
  }
}

@-ms-keyframes blink {
  from {
    opacity: 1;
    transform: scale(0.5);
  }

  to {
    opacity: 0.1;
    transform: scale(1.5);
  }
}

@-o-keyframes blink {
  from {
    opacity: 1;
    transform: scale(0.5);
  }

  to {
    opacity: 0.1;
    transform: scale(1.5);
  }
}
