.no_posts {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
}

#table_tr {
  cursor: pointer;
}

#table_tr:hover {
  background-color: rgb(225, 239, 249) !important;
}

#table_tr_td {
  display: none;
  padding: 0;
  border-bottom: none;
}

#table_tr_td_status {
  padding: 10px 5px 10px 5px;
  min-width: 180px;
  display: flex;
  justify-content: space-between !important;
}

#table_tr:hover #table_tr_td {
  display: block !important;
}

.status_cell_container {
  display: flex !important;
  align-items: center;
  justify-content: space-between !important;
  width: 100%;
}

#table_tr_td:hover #table_tr_td_status {
  display: none !important;
}

#table_tr_td:hover #table_tr_td {
  display: block !important;
}

.status_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  font-size: 14px;
  margin-right: 10px;
}

.total_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 15px;
  margin-right: 10px;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid black;
}

.total_div_count {
  background-color: black;
  color: white;
  font-weight: 700;
  width: 25px;
  height: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin-right: 5px;
  font-size: 12px;
  margin-left: 10px;
  min-width: fit-content;
}

.add_sales_fieldSet {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 52px;
  height: auto;
  padding: 20px 10px 20px;
  background-color: #fff;
}

.add_sales_fieldSet_legend {
  background-color: #a81a31;
  color: #fff;
  padding: 0px 10px 0px;
}

.save_button_div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.add_sales_fieldSet_legend_outlined {
  color: #a81a31;
  background-color: #fff;
  padding: 0px 10px 0px;
  font-weight: 600;
}
