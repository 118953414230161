.actionDiv {
  display: flex;
  /* width: 20px; */
  /* height: 20px; */
  /* margin-top: 5px; */
  /* margin-bottom: 5px; */
}

.actionButton {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.actionButton:hover {
  filter: saturate(200%);
}

.actionBtnIcon {
  font-size: 16px;
  color: #fff;
}
